.e-fullrow {
    width: 100%;
}

.e-splitter.e-splitter-horizontal .e-pane.e-pane-horizontal, .e-splitter.e-splitter-vertical .e-pane.e-pane-horizontal {
    overflow-y: hidden;
}

.e-upload.e-control-wrapper {
    display: none !important;
}

.file-manager-menu {
    height: 40px;
    padding-left: 15px;
    overflow-x: auto;
}

